"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setFraction = exports.removeFormat = exports.addCommas = void 0;
var addCommas = function (value, fractions) {
    if (fractions === void 0) { fractions = 2; }
    return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: fractions,
    }).format(value);
};
exports.addCommas = addCommas;
var removeFormat = function (value) {
    return parseFloat(value.replace(/,/g, "").replace("$", ""));
};
exports.removeFormat = removeFormat;
var setFraction = function (value, fractions) {
    if (fractions === void 0) { fractions = 2; }
    var factor = Math.pow(10, fractions);
    return parseFloat((Math.floor(value * factor) / factor).toFixed(fractions));
};
exports.setFraction = setFraction;
