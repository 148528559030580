"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var process_1 = require("./process");
$(function () {
    window.Echo.channel("historical-data").listen("HistoricalEvent", function (e) {
        var _a;
        var data = e.data;
        (_a = window.app.historicalData) === null || _a === void 0 ? void 0 : _a.event(data);
        switch (data.currency) {
            case "Gold":
                (0, process_1.processMetal)("gold", data);
                break;
            case "Silver":
                (0, process_1.processMetal)("silver", data);
                break;
            case "Platinum":
                (0, process_1.processMetal)("platinum", data);
                break;
            case "Palladium":
                (0, process_1.processMetal)("palladium", data);
                break;
            case "Canadian":
                (0, process_1.processCurrencies)("cad", data);
                break;
            case "Euro":
                (0, process_1.processCurrencies)("eur", data);
                break;
            case "Dollar":
                (0, process_1.processCurrencies)("usd", data);
                break;
        }
    });
});
