"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.processMetal = exports.processCurrencies = void 0;
var utils_1 = require("../exchange/utils");
var processCurrencies = function (currency, data) {
    if (data.currency === window.app.currency) {
        window.app.currencyRate = data.value;
    }
    window.app.currencies[currency] = data;
};
exports.processCurrencies = processCurrencies;
var processMetal = function (prefix, data) {
    var _a;
    if (window.app.metal === prefix) {
        (_a = window.app.historicalData) === null || _a === void 0 ? void 0 : _a.event(data, true);
    }
    window.app.metals[prefix] = data;
    // Current
    $(".".concat(prefix, "-price")).html("$" + (0, utils_1.addCommas)(data.value * window.app.currencyRate));
    $(".".concat(prefix, "-percentage")).html(data.change_percent.toString() + "%");
    $(".".concat(prefix, "-percentage")).removeClass("low high");
    $(".".concat(prefix, "-percentage")).addClass(data.change_percent > 0 ? "high" : "low");
    $(".".concat(prefix, "-high")).html("$" + (0, utils_1.addCommas)(data.daily_highest * window.app.currencyRate));
    $(".".concat(prefix, "-low")).html("$" + (0, utils_1.addCommas)(data.daily_lowest * window.app.currencyRate));
    if (data.change_percent > 0) {
        $(".cogreen-solid." + prefix).removeClass("d-none");
        $(".cogreen-solid." + prefix).addClass("d-inline");
        $(".cored-solid." + prefix).removeClass("d-inline");
        $(".cored-solid." + prefix).addClass("d-none");
    }
    else {
        $(".cogreen-solid." + prefix).removeClass("d-inline");
        $(".cogreen-solid." + prefix).addClass("d-none");
        $(".cored-solid." + prefix).removeClass("d-none");
        $(".cored-solid." + prefix).addClass("d-inline");
    }
    // USD
    if (window.app.currencies["usd"].value) {
        $(".".concat(prefix, "-usd-price")).html("$" + (0, utils_1.addCommas)(data.value * window.app.currencies["usd"].value));
        $(".".concat(prefix, "-usd-percentage")).html(data.change_percent.toString() + "%");
        $(".".concat(prefix, "-usd-percentage")).removeClass("low high");
        $(".".concat(prefix, "-usd-percentage")).addClass(data.change_percent > 0 ? "high" : "low");
        $(".".concat(prefix, "-usd-high")).html("$" + (0, utils_1.addCommas)(data.daily_highest * window.app.currencies["usd"].value));
        $(".".concat(prefix, "-usd-low")).html("$" + (0, utils_1.addCommas)(data.daily_lowest * window.app.currencies["usd"].value));
    }
    // CAD
    if (window.app.currencies["cad"].value) {
        $(".".concat(prefix, "-cad-price")).html("$" + (0, utils_1.addCommas)(data.value * window.app.currencies["cad"].value));
        $(".".concat(prefix, "-cad-percentage")).html(data.change_percent.toString() + "%");
        $(".".concat(prefix, "-cad-percentage")).removeClass("low high");
        $(".".concat(prefix, "-cad-percentage")).addClass(data.change_percent > 0 ? "high" : "low");
        $(".".concat(prefix, "-cad-high")).html("$" + (0, utils_1.addCommas)(data.daily_highest * window.app.currencies["cad"].value));
        $(".".concat(prefix, "-cad-low")).html("$" + (0, utils_1.addCommas)(data.daily_lowest * window.app.currencies["cad"].value));
    }
    // EUR
    if (window.app.currencies["eur"].value) {
        $(".".concat(prefix, "-eur-price")).html("$" + (0, utils_1.addCommas)(data.value * window.app.currencies["eur"].value));
        $(".".concat(prefix, "-eur-percentage")).html(data.change_percent.toString() + "%");
        $(".".concat(prefix, "-eur-percentage")).removeClass("low high");
        $(".".concat(prefix, "-eur-percentage")).addClass(data.change_percent > 0 ? "high" : "low");
        $(".".concat(prefix, "-eur-high")).html("$" + (0, utils_1.addCommas)(data.daily_highest * window.app.currencies["eur"].value));
    }
};
exports.processMetal = processMetal;
